import './bootstrap';
import '../css/app.css';

import { createApp, h } from 'vue';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { createInertiaApp, Head, Link } from '@inertiajs/vue3';
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/index';
import { createPinia } from 'pinia';

const pinia = createPinia();
const appName = import.meta.env.VITE_APP_NAME || '';

createInertiaApp({
	title: (title) => `${title} - ${appName}`,
	resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
	setup({ el, App, props, plugin }) {
		return createApp({ render: () => h(App, props) })
			.component('InertiaLink', Link)
			.component('InertiaHead', Head)
			.use(pinia)
			.use(plugin)
			.use(ZiggyVue, Ziggy)
			.mixin({
				methods: {
					route,
				},
			})
			.mount(el);
	},
	progress: {
		color: '#991b1b',
		delay: 300,
	},
}).then((r) => {});
